import type { TutorialDefinition } from '../types/tutorial';

export const tutorialSummary: TutorialDefinition = {
  id: 'tutorial-client-summary',
  route: '/client/summary',
  steps: [
    {
      target: '#collaborations-display-mode-selector-active-collaborations',
      content: 'You can find here all active collaborations',
    },
    {
      target: '#collaborations-display-mode-selector-review-creators',
      content:
        "Find here all creators to review: check their social media account, accept or decline them. If you're not sure yet, add them to your waiting list",
    },
    {
      target: '#collaborations-display-mode-selector-review-videos',
      content:
        'Go there to review all videos made by creators. Note that you must validate a video to get the link and post codes',
    },
    {
      target: '#collaborations-display-mode-selector-review-posts',
      content: 'Review all posted videos, check that they are posted as stated in the collaboration',
    },
    {
      target: '#collaborations-display-mode-selector-achieved-collaborations',
      content: 'Find here all collaborations that are achieved, and where no action is expected anymore',
    },
    {
      target: '#summary-table',
      content: 'Enjoy your collaborations!',
    },
  ],
};
