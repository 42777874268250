import { z } from 'zod';

import { tutorialSummary } from './tutorialSummary';

export const tutorials = [tutorialSummary];
export const tutorialsIds = [tutorialSummary.id] as const;

export const TutorialsIdsSchema = z.enum(tutorialsIds);

export type TutorialsIds = z.infer<typeof TutorialsIdsSchema>;
