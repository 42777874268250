import { useAtom } from 'jotai/index';
import { useMemo } from 'react';

import { loggersAtom } from '../atoms/loggers.atom';
import type { Logger } from '../types/logger';

export function useLogger(): { logger: Logger } {
  const [loggers] = useAtom(loggersAtom);

  const logger: Logger = useMemo(
    () => ({
      debug: (message?: any, ...optionalParams: any[]) =>
        loggers.forEach((logger) => logger.debug(message, ...optionalParams)),
      info: (message?: any, ...optionalParams: any[]) =>
        loggers.forEach((logger) => logger.info(message, ...optionalParams)),
      warn: (message?: any, ...optionalParams: any[]) =>
        loggers.forEach((logger) => logger.warn(message, ...optionalParams)),
      error: (message?: any, ...optionalParams: any[]) =>
        loggers.forEach((logger) => logger.error(message, ...optionalParams)),
    }),
    [loggers],
  );

  return {
    logger,
  } as const;
}
